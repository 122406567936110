import React, { useState, useEffect, useCallback } from 'react';
import {
  BarChart as ReBarChart, Bar, XAxis, YAxis,
  Tooltip, CartesianGrid, ResponsiveContainer
} from 'recharts';
import styled from 'styled-components';
import { getBarChartData } from '../services/api';
import { ClipLoader } from 'react-spinners'; // Importing the ClipLoader from react-spinners

const BarChart = () => {
  const [month, setMonth] = useState('January');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  const fetchBarChartData = useCallback(async (selectedMonth) => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await getBarChartData({ month: selectedMonth });
      setData(response.data); // Directly use the response data
    } catch (error) {
      console.error("Failed to fetch bar chart data:", error);
      setData([]);
    } finally {
      setLoading(false); // Set loading to false after fetching is complete
    }
  }, []);

  useEffect(() => {
    fetchBarChartData(month);
  }, [fetchBarChartData, month]);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  return (
    <ChartContainer>
      <Title>Bar Chart - {month}</Title>
      <MonthDropdown value={month} onChange={handleMonthChange}>
        {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((monthName) => (
          <option key={monthName} value={monthName}>{monthName}</option>
        ))}
      </MonthDropdown>

      {loading ? ( // Show spinner when loading
        <LoaderContainer>
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <LoadingText>Loading data...</LoadingText>
        </LoaderContainer>
      ) : data.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <ReBarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="range" />
            <YAxis />
            <Tooltip formatter={(value) => `${value} transactions`} />
            <Bar dataKey="count" fill="#8884d8" />
          </ReBarChart>
        </ResponsiveContainer>
      ) : (
        <NoDataMessage>No data available for {month}</NoDataMessage>
      )}
    </ChartContainer>
  );
};

export default BarChart;

const ChartContainer = styled.div`
  padding: 20px;
  background-color: #ffe599;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
`;

const MonthDropdown = styled.select`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const NoDataMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #ff0000;
  margin-top: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
`;

const LoadingText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #123abc;
`;

