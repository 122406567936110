import React, { useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { listTransactions } from '../services/api';

const TransactionsTable = () => {
  const [month, setMonth] = React.useState('January');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [perPage] = React.useState(5);
  const [transactions, setTransactions] = React.useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageClicked, setImageClicked] = useState(false);

  const fetchTransactions = async (newPage) => {
    if (!month) {
      toast.error('Month is required.');
      return;
    }
    try {
      const response = await listTransactions({ month, search: searchQuery, perPage, page: newPage || page });
      if (response.data.length === 0 && newPage) {
        toast.info('There is no data for the next page.');
      } else {
        setTransactions(response.data);
        if (newPage) {
          setPage(newPage);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error || 'An error occurred. Please try again.');
      } else {
        toast.error('Network error. Please check your connection.');
      }
    }
  };

  React.useEffect(() => {
    fetchTransactions();
  }, [month, searchQuery, page]);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setMonth(selectedMonth);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageClicked(true);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
    setImageClicked(false);
  };

  const filteredTransactions = (transactions || []).filter(transaction =>
    transaction.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.price.toString().includes(searchQuery)
  );

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleNextPage = () => {
    fetchTransactions(page + 1);
  };

  return (
    <TableContainer>
      <Header>Transaction Dashboard - {month}</Header>
      
      {imageClicked && <Note>You clicked on the image</Note>}
      
      <Controls>
        <SearchInput 
          type="text" 
          placeholder="Search transaction" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
        />
        <MonthDropdown
          value={month}
          onChange={handleMonthChange}
        >
          {months.map(monthName => (
            <option key={monthName} value={monthName}>{monthName}</option>
          ))}
        </MonthDropdown>
      </Controls>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Title</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Price</TableHeader>
            <TableHeader>Category</TableHeader>
            <TableHeader>Sold</TableHeader>
            <TableHeader>Image</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {filteredTransactions.slice((page - 1) * perPage, page * perPage).map(transaction => (
            <TableRow key={transaction._id}>
              <TableCell>{transaction.title}</TableCell>
              <TableCell>
                <Description>
                  {transaction.description.slice(0, 50)}...
                  <ReadMore onClick={() => alert(transaction.description)}>Read More</ReadMore>
                </Description>
              </TableCell>
              <TableCell>{transaction.price}</TableCell>
              <TableCell>{transaction.category}</TableCell>
              <TableCell>{transaction.sold ? "Yes" : "No"}</TableCell>
              <TableCell>
                <img 
                  src={transaction.image} 
                  alt={transaction.title} 
                  onClick={() => handleImageClick(transaction.image)}
                  style={{ width: '100px', height: 'auto', cursor: 'pointer' }} 
                />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <PageInfo>Page No: {page}</PageInfo>
        <PageControls>
          <PageButton onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</PageButton>
          <PageButton onClick={handleNextPage}>Next</PageButton>
        </PageControls>
      </Pagination>

      {selectedImage && (
        <Overlay onClick={handleCloseImage}>
          <EnlargedImageContainer>
            <CloseButton onClick={handleCloseImage}>×</CloseButton>
            <EnlargedImage src={selectedImage} alt="Enlarged" />
          </EnlargedImageContainer>
        </Overlay>
      )}

      <ToastContainer />
    </TableContainer>
  );
};

export default TransactionsTable;

const TableContainer = styled.div`
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Note = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e7f4e4;
  color: #2d662d;
  border-left: 5px solid #2d662d;
  font-size: 16px;
  text-align: center;
`;

const Controls = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 25px;
  border: 2px solid #ffa500;
  background-color: #ffe599;
  width: 250px;
`;

const MonthDropdown = styled.select`
  padding: 10px;
  font-size: 16px;
  border-radius: 25px;
  border: 2px solid #ffa500;
  background-color: #ffe599;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #ffe599;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f0f0f0;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  background-color: #ffa500;
  color: #fff;
  font-size: 16px;
  border: 1px solid #000;
  text-align: center;
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #000;
`;

const Description = styled.span`
  display: inline-block;
  max-width: 200px;
`;

const ReadMore = styled.span`
  color: #007bff;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const PageInfo = styled.span`
  font-size: 16px;
`;

const PageControls = styled.div`
  display: flex;
  gap: 10px;
`;

const PageButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  border: 2px solid #ffa500;
  background-color: #ffe599;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const EnlargedImageContainer = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
`;

const EnlargedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  &:hover {
    background-color: #f0f0f0;
  }
`;
