import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { getStatistics } from '../services/api';

const Statistics = () => {
  const [stats, setStats] = useState({});
  const [month, setMonth] = useState('January');

  const fetchStatistics = useCallback(async () => {
    try {
      const response = await getStatistics({ month });
      setStats(response.data);
    } catch (error) {
      console.error("Failed to fetch statistics:", error);
    }
  }, [month]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <StatisticsContainer>
      <Title>Statistics</Title>
      <MonthDropdown value={month} onChange={handleMonthChange}>
        {months.map(monthName => (
          <option key={monthName} value={monthName}>{monthName}</option>
        ))}
      </MonthDropdown>
      <StatsCard>
        <StatItem>
          <StatLabel>Total Sale</StatLabel>
          <StatValue>{stats.totalAmount || 0}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Total Sold Items</StatLabel>
          <StatValue>{stats.totalItems || 0}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Total Not Sold Items</StatLabel>
          <StatValue>{stats.totalNotSold || 0}</StatValue>
        </StatItem>
      </StatsCard>
    </StatisticsContainer>
  );
};

export default Statistics;

const StatisticsContainer = styled.div`
  padding: 20px;
  margin-top: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
`;

const MonthDropdown = styled.select`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 12px;
  border: 2px solid #ffa500;
  background-color: #ffe599;
`;

const StatsCard = styled.div`
  background-color: #ffe599;
  border-radius: 12px;
  padding: 20px;
  width: fit-content;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StatItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StatLabel = styled.span`
  font-size: 18px;
  color: #333;
`;

const StatValue = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: #333;
`;
