import React, { useState, useEffect, useCallback } from 'react';
import { PieChart as RePieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import styled from 'styled-components';
import { getPieChartData } from '../services/api';

const PieChart = () => {
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('January');
  const [noData, setNoData] = useState(false);
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const fetchPieChartData = useCallback(async () => {
    try {
      const response = await getPieChartData({ month: selectedMonth });
      if (response.data.length === 0) {
        setNoData(true);
        setData([]);
      } else {
        setNoData(false);
        setData(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch pie chart data:", error);
    }
  }, [selectedMonth]);

  useEffect(() => {
    fetchPieChartData();
  }, [fetchPieChartData]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Generate legend data based on the categories in the data
  const legendData = data.map((entry, index) => ({
    category: entry.category,
    color: COLORS[index % COLORS.length]
  }));

  return (
    <ChartContainer>
      <Controls>
        <MonthDropdown
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months.map(monthName => (
            <option key={monthName} value={monthName}>{monthName}</option>
          ))}
        </MonthDropdown>
      </Controls>
      {noData ? (
        <NoDataMessage>No data available for {selectedMonth}.</NoDataMessage>
      ) : (
        <ChartWrapper>
          <ResponsiveContainer width="100%" height={300}>
            <RePieChart>
              <Pie
                data={data}
                dataKey="count"
                nameKey="category"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name) => [`${value} (${((value / data.reduce((acc, item) => acc + item.count, 0)) * 100).toFixed(2)}%)`, name]}
                labelFormatter={(label) => `Category: ${label}`}
              />
            </RePieChart>
          </ResponsiveContainer>
          <LegendWrapper>
            {legendData.map((entry, index) => (
              <LegendItem key={`legend-${index}`}>
                <LegendColor style={{ backgroundColor: entry.color }} />
                <LegendLabel>{entry.category}</LegendLabel>
              </LegendItem>
            ))}
          </LegendWrapper>
        </ChartWrapper>
      )}
    </ChartContainer>
  );
};

export default PieChart;

const ChartContainer = styled.div`
  padding: 20px;
  margin-top: 20px;
  background: #f0f2f5;
  border-radius: 8px;
`;

const Controls = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const MonthDropdown = styled.select`
  padding: 10px;
  font-size: 16px;
  border-radius: 25px;
  border: 2px solid #ffa500;
  background-color: #ffe599;
  
  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const LegendLabel = styled.span`
  font-size: 16px;
  color: #333;
`;

const NoDataMessage = styled.div`
  font-size: 18px;
  color: #ff4d4f;
  text-align: center;
  padding: 20px;
`;
