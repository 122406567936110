import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <CloseButton onClick={toggleSidebar}>✖</CloseButton>
      <NavItem
        to="/"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleSidebar}
      >
        Home
      </NavItem>
      <NavItem
        to="/dashboard/statistics"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleSidebar}
      >
        Statistics
      </NavItem>
      <NavItem
        to="/dashboard/transactions"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleSidebar}
      >
        Transactions
      </NavItem>
      <NavItem
        to="/dashboard/bar-chart"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleSidebar}
      >
        Bar Chart
      </NavItem>
      <NavItem
        to="/dashboard/pie-chart"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleSidebar}
      >
        Pie Chart
      </NavItem>
    </SidebarContainer>
  );
};

export default Sidebar;

const SidebarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: #333;
  padding: 20px;
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;

  @media (min-width: 769px) {
    transform: translateX(0);
  }
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const NavItem = styled(motion(Link))`
  color: #fff;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    color: #ffa500;
    background-color: #444;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px;
    margin: 5px;
  }
`;
