
// services\api.js
import axios from 'axios';

const api = axios.create({
  baseURL: '/api/transactions', // Proxy handles the base URL
});


export const initializeDatabase = () => api.get('/initialize-db');
export const listTransactions = (params) => api.get('/', { params });
export const getStatistics = (params) => api.get('/statistics', { params });
export const getBarChartData = (params) => api.get('/bar-chart', { params });
export const getPieChartData = (params) => api.get('/pie-chart', { params });
export const getCombinedData = (params) => api.get('/combined-data', { params });
