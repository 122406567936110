import React, { useState, useEffect } from 'react';
import { Select } from 'antd'; // Using Ant Design's Select component
import styled from 'styled-components'; // For additional styling
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'; // For charting
import { getCombinedData } from '../services/api'; // Import the function

const { Option } = Select;

const Dashboard = () => {
  const [month, setMonth] = useState('June');
  const [statistics, setStatistics] = useState({ totalAmount: 0, totalItems: 0, totalNotSold: 0 });
  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [month]);

  const fetchData = async () => {
    try {
      const { data } = await getCombinedData({ month });
      setStatistics(data.statistics || { totalAmount: 0, totalItems: 0, totalNotSold: 0 });
      setBarChartData(data.barChartData || []);
      setPieChartData(data.pieChartData || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleMonthChange = (value) => {
    setMonth(value);
  };

  return (
    <DashboardContainer>
      <SearchAndSelectContainer>
        <CustomSelect defaultValue={month} onChange={handleMonthChange}>
          {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(month => (
            <Option key={month} value={month}>{month}</Option>
          ))}
        </CustomSelect>
        <SearchButton onClick={fetchData}>Search</SearchButton>
      </SearchAndSelectContainer>
      
      <h2>Statistics for {month}</h2>
      <StatisticsContainer>
        <StatisticItem>
          <p>Total Amount</p>
          <h4>{statistics.totalAmount?.toFixed(2)}</h4>
        </StatisticItem>
        <StatisticItem>
          <p>Total Items Sold</p>
          <h4>{statistics.totalItems}</h4>
        </StatisticItem>
        <StatisticItem>
          <p>Total Not Sold Items</p>
          <h4>{statistics.totalNotSold}</h4>
        </StatisticItem>
      </StatisticsContainer>
      
      <ChartContainer>
        <h3>Bar Chart Stats - {month}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={barChartData} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
            <XAxis dataKey="category" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#26c6da" barSize={50} />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>

      <ChartContainer>
        <h3>Pie Chart Stats - {month}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie data={pieChartData} dataKey="count" nameKey="category" cx="50%" cy="50%" outerRadius={80}>
              {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </ChartContainer>
    </DashboardContainer>
  );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default Dashboard;

// Styled Components


const DashboardContainer = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  font-family: 'Arial, sans-serif';

  @media (max-width: 850px) {
    padding: 15px;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }

  @media (max-width: 400px) {
    padding: 5px;
  }
`;

const StatisticsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StatisticItem = styled.div`
  text-align: center;
  margin: 0 10px;

  p {
    font-size: 14px;
    margin: 5px 0;
    color: #00796b;
  }

  h4 {
    font-size: 24px;
    margin: 5px 0;
    color: #004d40;
  }

  @media (max-width: 600px) {
    p {
      font-size: 12px;
    }

    h4 {
      font-size: 20px;
    }
  }

  @media (max-width: 400px) {
    p {
      font-size: 10px;
    }

    h4 {
      font-size: 16px;
    }
  }
`;

const ChartContainer = styled.div`
  margin-top: 20px;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 10px;

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    color: #00796b;
  }

  @media (max-width: 850px) {
    h3 {
      font-size: 16px;
    }
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 14px;
    }
  }

  @media (max-width: 400px) {
    h3 {
      font-size: 12px;
    }
  }
`;

const SearchAndSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const CustomSelect = styled(Select)`
  width: 200px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ffc107;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffb300;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }
`;
