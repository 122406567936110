import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'antd/dist/reset.css'; // Updated for Ant Design v5
import Sidebar from './components/Sidebar';
import Statistics from './components/Statistics';
import TransactionsTable from './components/TransactionTable';
import BarChart from './components/BarChart';
import PieChart from './components/PieChart';
import styled from 'styled-components';
import Dashboard from './pages/Dashboard';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <Layout>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <Content>
          <ToggleButton onClick={toggleSidebar}>
            ☰
          </ToggleButton>
          <Routes>
            {/* Redirect root path to the desired route */}
            <Route path="/" element={<Navigate to="/dashboard/statistics" />} />
            <Route path="/dashboard/statistics" element={<Statistics />} />
            <Route path="/dashboard/transactions" element={<TransactionsTable />} />
            <Route path="/dashboard/bar-chart" element={<BarChart />} />
            <Route path="/dashboard/pie-chart" element={<PieChart />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;

const Layout = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 250px; /* Adjust this value to match Sidebar's width */
  padding: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding-top: 60px; /* To account for the fixed navbar height on smaller screens */
  }
`;

const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1100;
  background-color: #ffa500;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e69500;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;
